.officersdiv
{
    margin-top: -350px;
    width:100%;
    display:flex;
    justify-content: center;
    font-family: 'montserrat','sans-serif';
   color:var(--db);
}
.officerscarousel
{
    background-color: var(--db);
    width:100%;
    height: 500px;
   
}
.officersHead
{
  margin-top: -68px;
  width: 100%;
  position: absolute;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  font-family: "montserrat";
  color:white;
  background-color: var(--lb);

}
.officersHead h1
{
  margin-top: 30px;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
   
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover {
      background:var(--green);
  }
  .container {
    display: flex;
    gap: 12px;
    max-width: 100%;
    width: 100%;
    background:var(--lb);
    padding: 30px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-padding: 30px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  .container .card {
    display: flex;
    flex: 0 0 25%;
    height: 550px;
    width:150px;
    margin-left: 2%;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-radius: 5px;
    background:var(--db);
    scroll-snap-align: start;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
 
  .card .image {
      height:150px;
      width: 220px;
    padding: 0px;
    /* background: #4070f4; */
    border-radius: 50%;
  }
  .image img {
    height: 300px;
    width: 220px;
    object-fit: cover;
    border-radius: 2%;
    /* border: 5px solid  var(--green); */
  }
  .card h2 {
    text-align: center;
    margin-top: 70%;
    color:#f5f5f5;
    font-size: 30px;
    font-weight: 600;
  }
  .card p {
    margin-top: 5%;
    font-size: 25px;
    font-weight: 400;
    color:#f5f5f5;
    text-align: center;
  }
.socialmediaoff
{
  margin-top: 10%;
  width:341px;
  height:25px;
  background-color: white;
  display:flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
}
.scmofficon
{
  width:5%;
  margin-top: 2.5px;
  margin-right: 2.5px;
  margin-left: 2.5px;
}
.scmvloff
{
  background-color: var(--db);
  height:20px;
  width:2px;
  margin-top:2px ;
  border-radius: 50px;
}
.card h4
{
  /* position: absolute; */
  color:white;
  margin-top: 5%;
  font-size: 23px;
}

@media screen and (max-width: 998px){
  .officersdiv
{
    margin-top: -350px;
    width:100%;
    display:flex;
    justify-content: center;
    font-family: 'montserrat','sans-serif';
   color:var(--db);
}
.officerscarousel
{
    background-color: var(--db);
    width:100%;
    height: 500px;
   
}
.officersHead
{
  margin-top: -68px;
  width: 100%;
  position: absolute;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  font-family: "montserrat";
  color:white;
  background-color: var(--lb);

}
.officersHead h1
{
  margin-top: 30px;
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
   
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover {
      background:var(--green);
  }
  .container {
    display: flex;
    gap: 12px;
    max-width: 100%;
    width: 100%;
    background:var(--lb);
    padding: 30px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-padding: 30px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
  .container .card {
    display: flex;
    flex: 0 0 25%;
    height: 400px;
    width:150px;
    margin-left: 2%;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-radius: 5px;
    background:var(--db);
    scroll-snap-align: start;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  }
 
  .card .image {
      height:150px;
      width: 220px;
    padding: 0px;
    /* background: #4070f4; */
    border-radius: 50%;
  }
  .image img {
    height: 300px;
    width: 220px;
    object-fit: cover;
    border-radius: 2%;
    /* border: 5px solid  var(--green); */
  }
  .card h2 {
    text-align: center;
    margin-top: 70%;
    color:#f5f5f5;
    font-size: 25px;
    font-weight: 600;
  }
  .card p {
    margin-top: 5%;
    font-size: 20px;
    font-weight: 400;
    color:#f5f5f5;
    text-align: center;
  }
.socialmediaoff
{
  margin-top: 10%;
  width:241px;
  height:25px;
  background-color: white;
  display:flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
}
.scmofficon
{
  width:6%;
  margin-top: 3px;
  margin-right: 2.5px;
  margin-left: 2.5px;
}
.scmvloff
{
  background-color: var(--db);
  height:20px;
  width:2px;
  margin-top:2px ;
  border-radius: 50px;
}
.card h4
{
  /* position: absolute; */
  color:white;
  margin-top: 5%;
  font-size: 23px;
}
}


@media screen and (max-width: 768px)
{
  .officersdiv
  {
    margin-top: 0px;

  }
  .officersHead
  {
    margin-top: -54px;
    font-size: 10px;
  }
  .container .card {
   
    height: 350px;
    width:120px;
  }
  .image img 
  {
    margin-left: 25px;
    margin-top: -20px;
    width:170px;
    height:250px;
  }

  .card h4
  {

    color:white;
    margin-top: 15%;
    font-size: 10px;
    text-align: center;
  }
  .card h2 {
    text-align: center;
    margin-top: 70%;
    color:#f5f5f5;
    font-size: 20px;
    font-weight: 600;
  }
  .card p {
    margin-top: 10%;
    font-size: 15px;
    color:#f5f5f5;
    text-align: center;
  }
  .socialmediaoff
{
  margin-top: 10%;
  width:161px;
  height:25px;
  background-color: white;
  display:flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
}
.scmofficon{
  width:8%;
}

}

