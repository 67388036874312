:root
{
    --lb:#3A5Ab4;
    --db:#00144A;
    --green:#03C988;
    --white:#FFFFFF;
}
.upcarddiv
{
    display: flex;
    justify-content: center;
}
.upeventcard
{
    margin-top: 50px;
    background-color: #00144A;
    width:75%;
    height:500px;
    border-radius:20px;
}
.upcardimg
{
    width:43%;
    margin-left:55%;
    margin-top:2%;
    border-radius:5px;
}
.eventcardtext
{
    margin-top: -44%;
    color:#03C988;
    margin-left:20px;
    font-size: 50px;
    font-family: 'montserrat';
}

.eventcardtext1
{
    margin-top: 50px;
    margin-left:20px;
    color:white;
    font-size: 45px;
    font-family: 'montserrat';
}

.eventcardtext2
{
    margin-top: 70px;
    margin-left:20px;
    color:white;
    font-family: 'helvetica';
}
.eventcardtext3
{
    margin-top: 20px;
    margin-left:20px;
    margin-right: 50%;
    text-align: justify;
    color:white;
    font-family: 'helvetica';
}
.eventcardtext2 a
{
    color: #03C988;
}


@media screen and (max-width: 998px){
  
    .upeventcard
    {
        height:750px;  
        width:450px;
    }
    .upcardimg
    {
        width:70%;
        margin-left:15%;
        margin-top:15%;
        
    }
    .eventcardtext
    {
        margin-top: 10%;
        text-align: center;
        color:#03C988;
        font-size: 45px;
        margin-left: 0px;
        font-family: 'montserrat';
    }
    
    .eventcardtext1
    {
        margin-left: 0px;
        margin-top: 20px;
        margin-left:20px;
        color:white;
        font-size: 25px;
        text-align: center;
    }
    
    .eventcardtext2
    {
        font-size: 12px;
        margin-top: 40px;
        margin-left:10%;
        margin-right: 10%;
        color:white;
        text-align: center;
    }
    .eventcardtext3
    {
        font-size: 12px;
        margin-top: 20px;
        margin-left:10%;
        margin-right: 10%;
        text-align: justify;
        color:white;
        font-family: 'helvetica';
    }
    .eventcardtext2 a
    {
        color: #03C988;
    }
}


@media screen and (max-width: 768px)
{
    .upeventcard
{
    height:750px; 
    width:350px; 
}
.upcardimg
{
    width:70%;
    margin-left:15%;
    margin-top:15%;
}
.eventcardtext
{
    margin-top: 10%;
    text-align: center;
    color:#03C988;
    font-size: 45px;
    margin-left: 0px;
    font-family: 'montserrat';
}

.eventcardtext1
{
    margin-left: 0px;
    margin-top: 20px;
    margin-left:20px;
    color:white;
    font-size: 25px;
    text-align: center;
}

.eventcardtext2
{
    font-size: 12px;
    margin-top: 40px;
    margin-left:10%;
    margin-right: 10%;
    color:white;
    text-align: center;
}
.eventcardtext3
{
    font-size: 12px;
    margin-top: 20px;
    margin-left:10%;
    margin-right: 10%;
    text-align: justify;
    color:white;
    font-family: 'helvetica';
}
.eventcardtext2 a
{
    color: #03C988;
}

}
