:root
{
    --lb:#3A5Ab4;
    --db:#00144A;
    --green:#03C988;
    --white:#FFFFFF;
}

.recruitment_div{
    background-color: var(--db);
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    color:white;
    border-radius: 20px;
    font-family: 'helvetica';
    padding: 20px;
}

.recruitment_div h1{
    margin-bottom: 20px;
}
.head_div .rec_head{
    text-align: center;
    font-family: 'montserrat';
}
.ready_para_div{
    margin-left: 5%;
    margin-right: 5%;
    text-align: justify;
}

.ready_para_div p{
    margin-bottom: 10px;
    font-size: 15px;
}

.ready_para_div h4{
    margin-top: 50px;
}

.apply_button{
    background-color: #03C988;
    border:none;
    padding:10px;
    width:100px;
    margin-left: 2%;
    margin-top: 20px;
    border-radius: 20px;
    font-family: 'montserrat';
    color:white;
    cursor: pointer;
}
.bot_para{
    margin-top: 20px;
}

@media screen and (max-width: 768px){
    .ready_para_div p{
        margin-bottom: 10px;
        font-size: 12px;
    }
    .ready_para_div h4{
        margin-top: 25px;
        margin-left: 5%;
        margin-bottom: 25px;
    }
    .application_list{
        font-size: 12px;
    }
    .apply_button{
        margin-left: 38.5%;
    }
}

    