
:root
{
    --lb:#3A5Ab4;
    --db:#00144A;
    --green:#03C988;
    --white:#FFFFFF;
}
.Acmhead
{
    margin-top: 20px;
    margin-left: 10%;
    font-family: 'helvetica';
    color: #3A5Ab4;
}
.aboutACMparagraph
{
    margin-top: 20px;
    text-align: justify;
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'helvetica';
    color:#3A5Ab4;
}
.aboutgeuacm
{
    margin-top: 25px;
    text-align: justify;
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'helvetica';
    color:#3A5Ab4;
}

.geuacmlogodiv
{
  margin-top: -1%;
}
.geuacmlogo
{
    width:20%;
    margin-left: 75%;
    margin-top: -50%;
}

.graphicerapara
{
    margin-top: 10px;
    text-align: justify;
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'helvetica';
    color:#3A5Ab4;

}

.graphiceradiv h1
{
    color:#3A5Ab4;
    margin-left:10%;
    margin-top: 60px;
    font-family: 'helvetica';
}

.geulogo
{
    width:30%;
    margin-top: -12%;
    margin-left: 70%;
}

.Acmlogocont
{
    width:25%;
    margin-left:20%;
    margin-top: 5%;
}

.infodiv
{
    font-family: 'helvetica';
    margin-top: -10%;
    margin-left: 50%;
    color:#3A5Ab4;
}
.hzline
{
    background-image: linear-gradient(var(--green),var(--lb));
    height:5px;
    width:100%;
    margin-top: 10%;
}

.geulogocont
{
    width:30%;
    margin-left: 15%;
    margin-top: 40px;
}
.infodiv2
{
    font-family: 'helvetica';
    color:#3A5Ab4;
    margin-top: -9%;
    margin-left: 50%;
}




@media screen and (max-width:768px)
{
    .Acmhead
{
    text-align: center;
    margin: 0px;
}

.aboutgeuacm
{
   
    margin-right: 10%;
  
}

.geuacmlogo
{
    width:0%;

}

.graphicerapara
{
    
    margin-left: 10%;
    margin-right: 10%;

}

.graphiceradiv h1
{
    text-align: center;
    margin: 0px;
    margin-top: 20px;
}

.geulogo
{
    width:0%;
  
}

.Acmlogocont
{
    width:30%;
    margin-left:34%;
    margin-top: 10%;
}

.infodiv
{
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
    color:#3A5Ab4;
}
.hzline
{
    background-image: linear-gradient(var(--green),var(--lb));
    height:5px;
    width:100%;
    margin-top: 10%;
}

.geulogocont
{
    width:50%;
    margin-left:24%;
    margin-top: 10%;
}
.infodiv2
{
    margin-top: 8%;
    margin-left: 10%;
    margin-right: 10%;
    color:#3A5Ab4;
}
}