.footer
{
    width:100%;
    height:200px;
    margin-top: 200px;
    background-color: #526D82;
}
.footerdiv
{
    display:flex;
    flex-direction: row;
    justify-content: center;
    padding:10px;
    margin :10px;
}
.instalogo
{
    margin: 20px;
    width:30px;
}
.vl3
{
    position: absolute;
    background-color: white;
    width:2px;
    margin-top: 20px;
    height:35px;
}
.hline
{
    color: white;
}
.rights
{
    margin-top: 10px;
    color : white;
   display: flex;
   justify-content: center;
}



@media screen and (max-width: 768px) { 

    .footer
    {
        height:150px;
        margin-top: 80px;
     
    }
    .footerdiv
    {
        padding:0px;
        margin :0px;
    }
    .instalogo
    {
        margin: 10px;
        width:25px;
    }
    .vl3
    {
        position: absolute;
        background-color: white;
        width:1px;
        margin-top: 8px;
        height:35px;
    }
    .hline
    {
        color: white;
    }
    .rights
    {
        font-size: 10px;
        color : white;
       display: flex;
       justify-content: center;
    }   
  
 }
