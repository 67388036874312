:root
{
    --lb:#3A5Ab4;
    --db:#00144A;
    --green:#03C988;
    --white:#FFFFFF;
}

.gallery_image_container{
    display: grid ;
    grid-template-columns: 1fr 1fr;
    margin-left: 4.5%;
}
.galimg{
    width:90%;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 20px  #888888;  
}
/* .vertimage{
    height: 100%;
    width: 100%;
} */

.eghead{
    color : var(--white);
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: var(--lb);
    border-radius: 5px;
    margin-left: 5%;
    margin-right: 5%;
    font-family: 'montserrat';
    
}
@media screen and (max-width: 768px){
    .gallery_image_container{
        display: grid ;
        grid-template-columns: 1fr;
       
    } 
}



