.instructordiv
{
    /* background-color: green; */
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top:50px;
    margin-bottom: 450px;
}
.ourinst h1,h3,h4
{
    font-family: 'montserrat';
    margin:5px;
}
.instimg
{
    width : 15%;
    border-radius: 5px;
    margin: 10px;
    border:solid 10px var(--db);
}

.advisordiv
{
 
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
   
}
.hodimg
{
    width:25%;
    border-radius: 5px;
    margin: 10px;
    border:solid 10px var(--db);
}
@media screen and (max-width: 768px)
{
    .instructordiv
{
    margin-top: 10px;
    margin-bottom: 70px;
}
    .instimg{
        width: 35%;
    }
}