:root
{
    --lb:#3A5Ab4;
    --db:#00144A;
    --green:#03C988;
    --white:#FFFFFF;
}
.cardcontainer
{
    display: flex;
    justify-content: center;
}
.eventcard
{
    margin-top: 50px;
    background-color: #00144A;
    width:75%;
    height:500px;
    border-radius:20px;
    color:white;
}
.eventcardimg
{
    width:43%;
    margin-left:55%;
    margin-top: 2%;
    border-radius: 5px;
}

.cardheading
{
    margin-top: -42%;
    margin-left: 40px;
    font-size: 45px;
    font-family: "montserrat";
}
.cardparagraph
{
    margin-left:40px;
    margin-top: 30px;
    text-align: justify;
    margin-right:57%;
    font-family: "helvetica";
}


@media screen and (max-width:998px){
    .cardcontainer
    {
        display: flex;
        justify-content: center;
    }
    .eventcard
    {
        margin-top: 50px;
        background-color: #00144A;
        width:450px;
        height:850px;
        border-radius:20px;
        color:white;
    }
    .eventcardimg
    {
        width:70%;
        margin-left:15%;
        margin-top: 15%;
        border-radius: 5px;
    }
    
    .cardheading
    {
        margin-top: 2%;
        margin-left: 40px;
        text-align: center;
        font-size: 30px;
        font-family: "montserrat";
    }
    .cardparagraph
    {
        font-size: 15px;
        margin-left:40px;
        margin-top: 30px;
        text-align: justify;
        margin-right:40px;
        font-family: "helvetica";
    }
}


@media screen and (max-width:768px)
{

.eventcard
{
    width:75%;
    height:750px;
    width: 350px;
   
}
.eventcardimg
{
    width: 70%;
    margin-left:15%;
    margin-top:15%;
}

.cardheading
{
    margin-top: 10%;
    text-align: center;
    font-size: 35px;
    margin-left: 0px;
    font-family: "montserrat";
}
.cardparagraph
{
    margin-left:40px;
    margin-top: 30px;
    text-align: justify;
    margin-right:40px;
    font-family: "helvetica";
    font-size: 12px;
}
}