:root {
  --lb: #3a5ab4;
  --db: #00144a;
  --green: #03c988;
}
.recenteventdiv {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  font-family: "montserrat";
  color: white;
  background-color: #3a5ab4;
}

.cards_event {
  background-color: #3a5ab4;
  display: flex;
  justify-content: space-evenly;
}
.receventcard,
.receventcard2 {
  width: 550px;
  height: 300px;
  background-color: #00144a;
  color: white;
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 10px;
}

.reccardhead {
  font-family: "montserrat";
  margin-left: 10px;
  margin-top: 10px;
  font-size: 35px;
}
.receventparagraph {
  font-family: "helvetica";
  margin-left: 20px;
  margin-right: 310px;
  text-align: justify;
  margin-top: 20px;
}
.card1post {
  width: 40%;
  margin-left: 57%;
  margin-top: -34%;
  border-radius: 5px;
}
.card2post {
  width: 40%;
  margin-left: 57%;
  margin-top: -37%;
  border-radius: 5px;
}
.speakername {
  margin-top: 10px;
}
.recenthead {
  margin-top: 60px;
}


@media screen and (max-width: 998px){
 
  .recenteventdiv {
    margin-top: 80px;
  }

  .cards_event {
    background-color: #3a5ab4;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .receventcard,
  .receventcard2 {
    width: 250px;
    height: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
  }

  .reccardhead {
    font-family: "montserrat";
    margin-top: 20px;
    font-size: 25px;
    text-align: center;
  }
  .receventparagraph {
    font-family: "helvetica";
    margin-left: 20px;
    margin-right: 20px;
    text-align: justify;
    margin-top: 10px;
  }
  .card1post {
    width: 70%;
    border-radius: 5px;
    margin-left: 15%;
    margin-top: 10%;
    border: solid white;
  }
  .card2post {
    width: 70%;
    border-radius: 5px;
    margin-left: 15%;
    margin-top: 10%;
    border: solid white;
  }
  .speakername {
    margin-top: 10px;
  }
  .recenthead {
    margin-top: 30px;
    font-size: 30px;
  }
}


@media screen and (max-width: 768px) {
  .recenteventdiv {
    margin-top: 80px;
  }

  .cards_event {
    background-color: #3a5ab4;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .receventcard,
  .receventcard2 {
    width: 250px;
    height: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
  }

  .reccardhead {
    font-family: "montserrat";
    margin-top: 20px;
    font-size: 25px;
    text-align: center;
  }
  .receventparagraph {
    font-family: "helvetica";
    margin-left: 20px;
    margin-right: 20px;
    text-align: justify;
    margin-top: 10px;
  }
  .card1post {
    width: 70%;
    border-radius: 5px;
    margin-left: 15%;
    margin-top: 10%;
    border: solid white;
  }
  .card2post {
    width: 70%;
    border-radius: 5px;
    margin-left: 15%;
    margin-top: 10%;
    border: solid white;
  }
  .speakername {
    margin-top: 10px;
  }
  .recenthead {
    margin-top: 30px;
    font-size: 30px;
  }
}
