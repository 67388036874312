:root
{
    --lb:#3A5Ab4;
    --db:#00144A;
    --green:#03C988;
}
 /* background-image: url(./geupic.png); */
.background
{
    background-image: url(./geupic.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 1000px;

}

.welcometext
{
   margin-top: -670px;
    margin-left: 30px;
    font-size: 35px;
    padding:350px 0px 0px 0px;
    color:white;
    font-family: 'montserrat','sans-serif';   
}
.joinusb
{
   
    background-color: #03C988;
    border: none;
    width:100px;
    border-radius:20px;
    font-size: 15px;
    padding:5px;
    color: white;
}
@media screen and (max-width: 998px)
{
    .background
{
    background-image: url(./geupic.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;

}

.welcometext
{
   margin-top: -580px;
    margin-left: 30px;
    font-size: 25px;
    padding:350px 0px 0px 0px;
    color:white;
    font-family: 'montserrat','sans-serif';   
}
.joinusb
{
   
    background-color: #03C988;
    border: none;
    width:100px;
    border-radius:20px;
    font-size: 15px;
    padding:5px;
    color: white;
}
}

@media screen and (max-width: 768px)
{
    .background
    {
        height:400px;
    }
    .welcometext
    {
        margin-top: -290px;
        font-size: 15px;
        padding:150px 0px 0px 0px;
     
    }
    .joinusb
    {
        font-size: 10px;
        padding:2px;
        width:70px;
    }
}