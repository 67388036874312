.reviewform
{
    width:90%;
    height: 830px;
    background-color: var(--db);
    color:white;
    border-radius:20px;
}
.reviewformhead{
    margin-top: 5%;
    font-family: 'montserrat';
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}
.parentdiv{
    align-items: center;
    margin-top: 20px;
}
.joining_para{
    margin-right: 5%;
    margin-left: 5%;
    text-align: justify;
    font-family: 'helvetica', 'sans-serif';
}
.benefits{
    margin-left: 5%;
}
.benefit_para{
    text-align: justify;
    margin-right: 15%;
    font-family: 'helvetica', 'sans-serif';
}


@media screen and (max-width: 998px){
    .reviewform
    {
        width:90%;
        height: 900px;
       
    }
}

@media screen and (max-width: 768px)
{
    .reviewform
    {
        width:90%;
        height: 1050px;
        background-color: var(--db);
        color:white;
        border-radius:20px;
    }
    .reviewformhead{
        margin-top: 5%;
        font-family: 'montserrat';
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
    }
    .parentdiv{
        align-items: center;
        margin-top: 20px;
    }
    .joining_para{
        font-size: 12px;
        margin-right: 5%;
        margin-left: 5%;
        text-align: justify;
        font-family: 'helvetica', 'sans-serif';
    }
    .benefits{
        font-size: 14px;
        margin-left: 5%;
    }
    .benefit_para{
        font-size: 12px;
        text-align: justify;
        margin-right: 15%;
        font-family: 'helvetica', 'sans-serif';
    }
   
}