:root
{
    --lb:#3A5Ab4;
    --db:#00144A;
    --green:#03C988;
}
.acmlogoaboutus
{
    width:25%;
    margin-top:70px;
    margin-left:30px;
}
.paragraphdiv
{
    display: inline-block;
    margin-top: 0px;
}
.paragraph1
{
    margin-left: 30%;
    margin-top: -180px;
    margin-right: 10%;
    text-align: justify;
    color: #3A5Ab4;
    font-family: 'helvetica';
}
.aimdiv
{
    display: inline-block;
    margin-top: 5px;
    color: #3A5Ab4;
    font-family: 'helvetica';
}
.aim 
{
    margin-left: 30%;
    margin-right: 10%;
    text-align: justify;
}



@media screen and (max-width: 998px){
    .acmlogoaboutus
    {
        width:28%;
        margin-top:50px;
        margin-left:20px;
    }
    .paragraphdiv
    {
        display: inline-block;
        margin-top: 30px;
    }
    .paragraph1
    {
        margin-left: 35%;
        margin-top: -180px;
        margin-right: 10%;
        font-size: 15px;
       
    }
    .aimdiv
    {
        display: inline-block;
        margin-top: 5px;
       
    }
    .aim 
    {
        font-size: 15px;
        margin-left: 35%;
        
    } 
}

@media screen and (max-width: 768px)
{
   
    .paragraph1  
    {
        margin-top: 20px;
        font-size: 10px;
        margin-left: 10%;
    }
    .aim 
    {
        font-size: 10px;
        margin-left: 10%;
    }
    .acmlogoaboutus
    {
        margin-left: 35%;
    }
   
}

